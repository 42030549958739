import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['flash'];

  connect() {
    this.flash('Hello, Stimulus - upload!');
  }

  flash(message, danger = false) {
    if (danger) {
      this.flashTarget.className = ['text-danger'];
    }
    this.flashTarget.textContent = message;
  }
}
