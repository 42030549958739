import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let urlNeedsRevisiting = false;
    if (!document.cookie.includes('timeZone')) {
      urlNeedsRevisiting = true;
    }
    document.cookie = `timeZone=${
      window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;
    if (urlNeedsRevisiting === true) {
      Turbolinks.visit(window.location.href);
    }
  }
}
