import { Controller } from 'stimulus';

// direct DOM manipulation does not support undo
// needs execCommand https://stackoverflow.com/questions/28217539/allowing-contenteditable-to-undo-after-dom-modification
//   http://jsfiddle.net/qL6Lpy0c/
// https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
export default class extends Controller {
  static targets = ['editable', 'flash'];

  connect() {
    this.flash('Hello, Stimulus!');
  }

  flash(message, danger = false) {
    if (danger) {
      this.flashTarget.className = ['text-danger'];
    }
    this.flashTarget.textContent = message;
  }

  // https://javascript.info/selection-range
  // https://javascript.info/basic-dom-node-properties
  Selected(window) {
    const selection = window.getSelection();

    const anchorNode = selection.anchorNode;
    const firstRange = selection.getRangeAt(0);
    const startParentNode = anchorNode.parentNode;
    const startTagName = startParentNode.tagName;
    const reselectFirstRange = () => {
      selection.removeAllRanges();
      selection.addRange(firstRange);
    };

    return {
      anchorNode,
      reselectFirstRange,
      firstRange,
      selection,
      startParentNode,
      startTagName,
    };
  }

  canApplyBold(selected) {
    return (
      !selected.startParentNode.className.includes(this.classNames.bold) &&
      !(selected.startTagName === 'B')
    );
  }

  classNames = {
    bold: 'font-weight-bold',
  };

  action() {
    document.execCommand(event.target.textContent, false, null);
  }
  bold_old(event) {
    const selected = this.Selected(window);
    const range = selected.firstRange;

    if (range.collapsed) return; // selection start & end are the same

    if (this.canApplyBold(selected)) {
      try {
        const newNode = document.createElement('span');
        newNode.className = this.classNames.bold;
        range.surroundContents(newNode);
      } catch (e) {
        this.flash(e, true);
      }
    } else {
      // TODO: clear bold formatting
    }
    selected.reselectFirstRange();
  }

  // problem when newline is removed, solutions:
  //   non-relative font-size p[style="font-size:16px"] is a workaround
  //     https://github.com/yabwe/medium-editor/issues/543#issuecomment-244115477
  //     font-family: ... must be specified too
  //   https://github.com/yabwe/medium-editor/issues/543#issuecomment-230499220
  //   https://github.com/yabwe/medium-editor/issues/543#issuecomment-231521707
  concatInnerHtml(event) {
    event.preventDefault();
    const old = this.editableTarget.firstChild.innerHTML;
    console.log(old);
    this.editableTarget.firstChild.innerHTML = old;
  }

  // https://stackoverflow.com/a/46902361/998664
  // or, work from this? const position = window.getSelection().getRangeAt(0).startOffset;
  //   see clearFormatting() for more ideas
  caretPosition(event) {
    event.preventDefault();
    const range = window.getSelection().getRangeAt(0);
    const oldRange = range.cloneRange();
    const tmp = document.createElement('div');

    oldRange.selectNodeContents(this.editableTarget);
    oldRange.setEnd(range.endContainer, range.endOffset);
    console.log(tmp.innerHTML);
    console.log(oldRange.cloneContents());
    tmp.appendChild(oldRange.cloneContents());
    const position = tmp.innerHTML.length - 7; // 7 is <p></p>
    console.log(position);
  }

  // jquery has unwrap() https://api.jquery.com/unwrap/
  // for pure JS:
  //   https://plainjs.com/javascript/manipulation/unwrap-a-dom-element-35/
  clearFormatting(event) {
    event.preventDefault();

    const origRange = window.getSelection().getRangeAt(0);
    const startContainer = origRange.startContainer;
    const parentElement = startContainer.parentElement;

    if (parentElement.tagName !== 'P') {
      // new element
      // const element = document.createElement('EM');
      // element.textContent = startContainer.textContent;
      // const newNode = element;

      // new plain text node
      const textNode = document.createTextNode(startContainer.textContent);
      const newNode = textNode;

      parentElement.replaceWith(newNode);

      const selection = document.getSelection();
      const range = document.createRange();
      range.selectNodeContents(newNode); // node content, and done,
      // range.collapse(false); // or, move cursor to end of node, default
      // range.collapse(true); // or, move cursor to start of node
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  keyup() {
    const position = window.getSelection().getRangeAt(0).startOffset;

    const origRange = window.getSelection().getRangeAt(0);
    const startContainer = origRange.startContainer;
    const parentElement = startContainer.parentElement;

    if (parentElement.tagName !== 'P') {
      const element = document.createElement('EM');
      element.textContent = startContainer.textContent;
      const newNode = element;

      // const textNode = document.createTextNode(window.zzd.textContent);
      // const newNode = textNode

      parentElement.replaceWith(newNode);

      const selection = document.getSelection();
      const range = document.createRange();
      range.selectNodeContents(newNode); // node content, and done,
      // range.collapse(false); // or, move cursor to end of node, default
      // range.collapse(true); // or, move cursor to start of node
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}
